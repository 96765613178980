<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{counterparty}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'pkosmr/CounterpartyListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        Назад к списку
      </VBtn>
    </div>
    <div :class="$style.inner">
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" />
      <template v-if="current">
        <VAlert
                v-if="(isReviewer || isSpectator) && current.hasApprovedForm"
                color="primary"
                type="warning"
        >Контрагент проходит повторную предквалификацию</VAlert>
        <CounterpartyInfo :value="current" />
        <template v-if="isPrepare && isContragent">
          <VAlert type="error" dense :icon="false">
            <div class="body-2">
              <template v-if="current.isBlocked && !!current.dateEndBlock.length">
                <b>Анкета заблокирована для отправки на согласование до {{ current.dateEndBlock }} после нескольких отклонений проверяющими.</b>
              </template>
              <template v-else>
                Уважаемый контрагент, для прохождения квалификации <b class="text-decoration-underline">обязательно</b>
                требуется наличие свидетельства СРО на опасные производственные объекты.
              </template>
            </div>
          </VAlert>
        </template>
        <CounterpartyCard label="Прогресс согласования" class="mb-6">
          <Stepper v-if="current && current.approval" :items="current.approval" :id="counterparty" />
        </CounterpartyCard>
        <CounterpartyDetail
            :id="current.id"
            :status="current.status.value"
            :values="current.items"
            :comments="commentsInCurrent"
            :pending="pending"
            :reviewer="current.reviewer"
        />
        <CounterpartyInfo class="mt-6" :value="current" />
        <div class="my-4">
          <VBtn depressed @click="$router.push({ name: 'pkosmr/CounterpartyListView' })">
            <VIcon>mdi-arrow-left</VIcon>
            Назад к списку
          </VBtn>
        </div>
        <div class="mt-6">
          <CounterpartyHistory :items="commentsInCurrent" />
        </div>
        <CounterpartyCard label="История согласования" class="mt-6">
          <ApprovalHistory :items="current.approvalHistory" />
        </CounterpartyCard>
      </template>
    </div>
    <VDialog :value="$route.name === 'pkosmr/CounterpartyCommentsView'"  max-width="900" scrollable persistent>
      <CounterpartyCommentsView :counterparty="counterparty" :question="question" />
    </VDialog>
  </div>
</template>

<script>
import { find, get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkosmr/enums';
import CounterpartyInfo from '@/components/pkosmr/CounterpartyInfo/CounterpartyInfo';
import CounterpartyDetail from '@/components/pkosmr/CounterpartyDetail/CounterpartyDetail';
import CounterpartyHistory from '@/components/pkosmr/CounterpartyHistory/CounterpartyHistory';
import CounterpartyCard from '@/components/pkosmr/CounterpartyCard/CounterpartyCard';
import ApprovalHistory from '@/components/general/ApprovalHistory/ApprovalHistory';
import Stepper from '@/components/pkosmr/Stepper/Stepper';
import CounterpartyCommentsView from '@/views/pkosmr/CounterpartyCommentsView/CounterpartyCommentsView';
export default {
  name: 'CounterpartyDetailView',
  components: {
    CounterpartyCommentsView,
    CounterpartyInfo,
    CounterpartyDetail,
    CounterpartyHistory,
    CounterpartyCard,
    ApprovalHistory,
    Stepper,
  },
  props: {
    counterparty: { type: String },
    question: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'pkosmr/pending',
      current: 'pkosmr/counterparty',
      commentsInCurrent: 'pkosmr/commentsByQuestion',
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    isPrepare() {
      return get(this.current, 'status.value') === get(STATUSES, 'PREPARE.value', 'PREPARE');
    },
    commentsInDialog() {
      return get(this.commentsInCurrent, this.question, []);
    },
    approvalHistory() {
      return get(this.current, 'approvalHistory', []);
    },
    hasDraftComments() {
      return !!find(this.commentsInDialog, { draft: true });
    },
    hasActualComments() {
      return !!find(this.commentsInDialog, { actual: true });
    },
    actualCommentId() {
      return get(find(this.commentsInDialog, { actual: true }), 'id');
    }
  },
  methods: {
    ...mapActions({
      fetchCounterpartyDetail: 'pkosmr/fetchCounterpartyDetail',
      fetchCommentList: 'pkosmr/fetchCommentList',
    }),
  },
  watch: {
    counterparty: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchCounterpartyDetail({ id });
          this.fetchCommentList({ id });
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
