<template>
  <VBtn :color="!!actual? 'primary': 'light'" depressed small @click="$emit('click')">
    <template v-if="!!actual">
      {{`Актуальные замечания (${actual})`}}
    </template>
    <template v-else>
      {{`История замечаний (${total})`}}
    </template>
  </VBtn>
</template>

<script>
export default {
  name: 'BtnHistoryComments',
  props: {
    total: { type: Number },
    actual: { type: Number },
  }
}
</script>

<style lang="scss" module>

</style>
