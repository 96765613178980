<template>
  <div :class="$style.root">
    <div v-if="!Object.keys(items).length" class="grey lighten-4 px-6 py-5">
      Замечаний нет
    </div>
    <VExpansionPanels v-else flat multiple>
      <VExpansionPanel class="grey lighten-4">
        <VExpansionPanelHeader v-text="'История замечаний'" class="subtitle-1" />
        <VExpansionPanelContent >
          <VExpansionPanels flat multiple>
            <VExpansionPanel v-for="(comments, code) in items" :items="comments" :key="code">
              <VExpansionPanelHeader v-text="headers[code]" class="subtitle-2" />
              <VExpansionPanelContent>
                <div v-for="(comment, code) in comments" class="mt-2" :key="code">
                  <Comment v-bind="{ ...comment }" />
                </div>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>
        </VExpansionPanelContent>
      </VExpansionPanel>
    </VExpansionPanels>
  </div>
</template>

<script>
import { mapValues, head, get } from 'lodash-es';
import Comment from '@/components/general/Comment/Comment';
export default {
  name: 'CounterpartyHistory',
  components: {
    Comment
  },
  props: {
    items: { type: Object }
  },
  computed: {
    headers() {
      return mapValues(this.items, (value) => get(head(value), 'question.name'))
    }
  }
}
</script>

<style module lang="scss">
.root {}
</style>
