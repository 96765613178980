<template>
  <VExpansionPanel :class="$style.root">
    <VExpansionPanelHeader class="title">
      <div class="d-flex align-center">
        <div class="title">{{ label }}</div>
        <div class="flex-grow-1 pl-4">
          <VTooltip v-if="validationErrorsInSectionCount" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="error">{{validationErrorsInSectionCount}}</VChip>
            </template>
            <span>Поля с ошибками валидации</span>
          </VTooltip>
          <VTooltip v-if="commentsInCurrentSectionCount['errors']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="error">{{commentsInCurrentSectionCount['errors']}}</VChip>
            </template>
            <span>Замечания</span>
          </VTooltip>
          <VTooltip v-if="commentsInCurrentSectionCount['warnings']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="primary">{{commentsInCurrentSectionCount['warnings']}}</VChip>
            </template>
            <span>Исправления</span>
          </VTooltip>
        </div>
        <div v-if="isReviewer && waitDecision && !isEmpty(questionsInSectionWithoutComments) && !pending" class="ml-2">
          <VBtn depressed small color="light" @click.stop="dialog = true">Общий комментарий</VBtn>
        </div>
        <div v-if="showFalseValuesButton" class="ml-2">
          <VTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <VBtn depressed small color="light" v-bind="attrs" v-on="on" @click.stop="setFalseValuesInSection">Компетенция отсутствует</VBtn>
            </template>
            <span>Нажмите кнопку, если компетенция из этого раздела к вам не применима</span>
          </VTooltip>
        </div>
        <div v-if="mark" class="px-4">
          <VTooltip left>
            <template v-slot:activator="{ on, attrs }">
              <VChip v-bind="attrs" v-on="on" color="info">{{mark}}%</VChip>
            </template>
            <span>Оценка</span>
          </VTooltip>
        </div>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent eager>
      <VExpansionPanels v-if="!isEmpty(filtered(sections))" flat multiple>
        <CounterpartyDetailSection
            v-for="(section, sectionCode) in filtered(sections)"
            v-bind="{...section}"
            :status="status"
            :readonly="readonly"
            :comments="comments"
            :hiddenKeys="hiddenKeys"
            :key="sectionCode"
            :pengind="pending"
            :code="sectionCode"
        />
      </VExpansionPanels>
      <div v-for="(question, code) in filtered(questions)" :class="[$style.item, getClassByCode(code)]" :key="code">
        <VRow>
          <VCol cols="12" md="4">
            <div :class="$style.title" v-html="question.label" />
            <div :class="$style.hint" v-html="question.hint" />
            <div class="mt-2">
              <BtnHistoryComments
                  v-if="isContragent && get(commentsInCurrentSection, [code, 'length'], 0)"
                  :total="get(commentsInCurrentSection, [code, 'length'], 0)"
                  :actual="get(commentsInCurrentSectionWithActual, [code, 'length'], 0)"
                  @click="toComments(code)"
              />
              <VBtn v-if="isReviewer && waitDecision" depressed small color="light" @click="toComments(code)">
                Добавить замечание
              </VBtn>
            </div>
          </VCol>
          <VCol cols="12" md="8">
            <CounterpartyDetailValues v-bind="{...question}" :readonly="readonlyControl(question)" />
            <div v-if="!isReviewer" class="mt-2">
              <VBadge v-if="notAdmissionControl(question.notAdmission)" color="error" content="Не допуск" />
            </div>
          </VCol>
        </VRow>
      </div>
    </VExpansionPanelContent>
    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <CommentCard :items="[]">
        <template v-slot:top>
          <VToolbar flat dark color="primary">
            <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
            <VSpacer/>
            <VBtn icon dark @click="dialog = false">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
        </template>
        <template v-slot:bottom>
          <VCardText>
            <div v-if="isReviewer" class="flex-grow-1 px-2 py-4 border-top-1">
              <div class="mb-4">
                <VCheckbox v-for="{code, label, title} in questionsInSectionWithoutComments" v-model="selected" class="mt-0" hide-details :value="code" :label="label || title" :key="code" />
              </div>
              <CommentForm :disabled="!selected.length && pending" @submit="onSubmitComment" />
            </div>
          </VCardText>
        </template>
      </CommentCard>
    </VDialog>
  </VExpansionPanel>
</template>

<script>
import { forEach, keys, set, pick, mapValues, flatten, filter, map, find, omit, reduce, get, merge, omitBy, isEmpty, pickBy } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkosmr/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CommentCard from '@/components/general/CommentCard/CommentCard';
import CounterpartyDetailValues from '@/components/pkosmr/CounterpartyDetailValues/CounterpartyDetailValues';
import BtnHistoryComments from './components/BtnHistoryComments';
export default {
  name: 'CounterpartyDetailSection',
  components: {
    CounterpartyDetailValues,
    BtnHistoryComments,
    CommentForm,
    CommentCard,
  },
  props: {
    code: { type: String },
    mark: { type: Number },
    comments: { type: Object },
    label: { type: String },
    status: { type: String },
    sections: { type: Object },
    questions: { type: Object },
    readonly: { type: Boolean, default: false },
    hiddenKeys: { type: Array, default: () => []},
    pending: { type: Boolean },
    errors: { type: Number },
    forward: { type: Object }
  },
  data() {
    return {
      validationErrorsInSectionCount: 0,
      dialog: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    id() {
      return this.$route.params.counterparty;
    },
    commentsInCurrentSection() {
      return pick(this.comments, [...keys(this.questions), ...flatten(map(this.sections, ({questions}) => keys(questions)))]);
    },
    commentsInCurrentSectionWithActual() {
      return mapValues(this.commentsInCurrentSection, (comments) => filter(comments, { actual: true }));
    },
    commentsInCurrentSectionWithActualOrDraft() {
      return mapValues(this.commentsInCurrentSection, (comments) => filter(comments, ({ actual, draft }) => actual || draft));
    },
    commentsInCurrentSectionCount() {
      return reduce(this.commentsInCurrentSection, (acc, comments, question) => {
        if (this.isContragent) {
          if (find(comments, { draft: true })) {
            acc['warnings'] += 1
            return acc;
          }
          if (find(comments, { actual: true })) {
            acc['errors'] += 1;
            return acc;
          }
          if (get(this.questions, [question, 'changed'], false)) {
            acc['warnings'] += 1;
            return acc;
          }
        }
        if (this.isReviewer) {
          if (find(comments, { draft: true })) {
            acc['warnings'] += 1
            return acc;
          }
          if (find(comments, { actual: true })) {
            acc['errors'] += 1;
            return acc;
          }
          if (get(this.questions, [question, 'changed'], false)) {
            acc['errors'] += 1;
            return acc;
          }
        }
        return acc;
      }, { errors: 0, warnings: 0 })
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    waitDecision() {
      return this.status === get(STATUSES, 'WAIT_FOR_DECISION.value');
    },
    questionsInSection() {
      const sections = this.filtered(this.sections);
      const questions = this.filtered(this.questions);
      const questionsInSection = reduce(map(sections, 'questions'), merge, questions);
      return omitBy(questionsInSection, { label: '' });
    },
    questionsInSectionWithoutComments() {
      const omitKeys = keys(this.commentsInCurrentSection);
      return omit(this.questionsInSection, omitKeys);
    },
    questionsInSectionWithTypeBool() {
      return pickBy(this.questionsInSection, ({ type }) => /BOOL/.test(type))
    },
    questionsInSectionWithTypeBoolWithoutFalseValue() {
      return pickBy(this.questionsInSectionWithTypeBool, (question) => {
        const readonly = get(question, 'readonly', false);
        const values = get(question, 'values.BOOL', []);
        return !isEmpty(filter(values, { value: ''})) && !readonly;
      });
    },
    showFalseValuesButton() {
      //Показываем для контрагента, в разделах с номером > 7 где есть вопросы с булевым типом без ответа
      return this.isContragent && this.code > 'section_7' && isEmpty(this.filtered(this.sections)) && !isEmpty(this.questionsInSectionWithTypeBoolWithoutFalseValue) && !this.readonly;
    },
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'pkosmr/fetchCommentList',
      createComment: 'pkosmr/createComment',
    }),
    get,
    isEmpty,
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    readonlyControl(question) {
      return question.readonly || this.readonly || !!this.ruleControl(question.rules);
    },
    ruleControl(rules) {
      return find(rules, (rule) => find(rule, (value, code) => {
        const field = get(this.questions, code);
        const val = get(field, ['values', 'LIST', 0, 'value'], ''); // TODO: в составных полях есть проблемы с таким подходом
        return value === val;
      }));
    },
    notAdmissionControl(rules) {
      return find(rules, (rule) => find(rule, (value, code) => {
        const field = get(this.questions, code);
        const val = get(field, ['values', 'LIST', 0, 'value'], ''); // TODO: в составных полях есть проблемы с таким подходом
        return value === val;
      }));
    },
    getClassByCode(code) {
      const question = get(this.questions, code);
      const comments = get(this.commentsInCurrentSection, code);
      const changed = get(question, 'changed', false);
      const disabled = this.ruleControl(question.rules);
      const notAdmission = this.notAdmissionControl(question.notAdmission);

      if (disabled) {
        if (notAdmission) return this.$style.muted + ' error lighten-4'
        else return this.$style.muted;
      }
      if (notAdmission) return 'error lighten-4';

      if (this.isContragent) {
        if (!isEmpty(find(comments, { draft: true }))) return 'orange lighten-4';
        if (!isEmpty(find(comments, { actual: true }))) return 'error lighten-4';
        if (changed && !isEmpty(comments)) return 'orange lighten-4';
      }

      if (this.isReviewer) {
        if (find(comments, { draft: true })) return 'orange lighten-4';
        if (find(comments, { actual: true})) return 'error lighten-4';
        if (changed) return 'error lighten-4';
      }
      return '';
    },
    toComments(question) {
      this.$router.push({ name: 'pkosmr/CounterpartyCommentsView', params: { question }})
    },
    onSubmitComment(message) {
      const id = this.id;
      const question = this.selected;
      this.dialog = false;
      this.createComment({ id, question, message }).then(() => {
        this.fetchCommentList({ id });
      });
    },
    setFalseValuesInSection() {
      forEach(this.questionsInSectionWithTypeBool, (question) => {
        set(question, 'values.BOOL', map(get(question, 'values.BOOL'), (item) => ({ ...item, value: '0' })))
      });
    },
    onUpdateCount() {
      this.validationErrorsInSectionCount = filter(get(this.forward, 'inputs'), ({ hasError, $el }) => {
        return hasError && this.$el.contains($el);
      }).length;
    }
  },
  watch: {
    errors: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.onUpdateCount());
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  // Костыль позволяющий правильно высчитать высоту VTextarea auto-grow с параметром "eager" компонента VExpansionPanelContent
  :global(.v-expansion-panel-content[style*="display: none"]) {
    display: block !important;
    opacity: 0;
    height: 0;
    pointer-events: none;
  }
}
.title {
  font-weight: bold;
  font-size: 14px;
}
.hint {
  font-style: italic;
  font-size: 13px;
}
.item {
  padding: 20px 24px;
  margin: 0 -24px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.muted {
  opacity: 0.4;
  pointer-events: none;
}
</style>
