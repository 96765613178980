<template>
  <div v-if="!items.length">
    История согласования пуста
  </div>
  <VTimeline
      v-else
      dense
      clipped
  >
    <VTimelineItem
        v-for="(item, index) in items"
        :key="item.id"
        :color="index > 0 ? 'grey' : 'primary'"
        class="mb-4"
        small
    >
      <strong>{{ item.author }}</strong>
      <small v-if="!!item.role.length"> ({{ item.role }})</small>
      <VChip
          class="white--text ml-2 mr-1"
          :color="getApprovalColor(item.code)"
          label
          small
      >
        {{ item.status }}
      </VChip>
      <div><small>{{ item.date }}</small></div>
      <div v-if="item.publicComment">
        <i>Комментарий для контрагента:</i>
        {{ item.publicComment }}
      </div>
      <div v-if="item.internalComment">
        <i>Внутренний комментарий:</i>
        {{ item.internalComment }}
      </div>
      <div v-if="!!item.files && !!item.files.length">
        <i>Приложенные файлы:</i>
        <FileFieldMultiple :value="item.files" readonly />
      </div>
    </VTimelineItem>
  </VTimeline>
</template>

<script>
import {STATUSES} from '@/store/pkomain/enums';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';

export default {
  name: 'ApprovalHistory',
  components: {
    FileFieldMultiple,
  },
  props: {
    items: { type: Array, default: () => [], },
  },
  methods: {
    getApprovalColor(statusCode) {
      if (statusCode === STATUSES.NO) return 'error';
      if (statusCode === STATUSES.YES) return 'success';
      if (statusCode === STATUSES.REWORK) return 'warning';
      return 'grey';
    },
  }
}
</script>

<style module lang="scss">

</style>